import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  submit(event) {
    if (location.hostname != 'localhost') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({'event': 'SubmitFormContacts'});
      gtag('js', new Date());
    }

    var element = document.getElementById("message_partial");

    element.classList.add('sending-alert');
    element.innerHTML = event.params.alert;

    setTimeout(() => { document.getElementById("contacts-form").reset() }, 1000);

    setTimeout(() => {
      element.classList.remove('sending-alert');
      element.innerHTML = '';
    }, 5000);
  }
}
